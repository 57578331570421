

export const form = () => {

  const form = document.querySelector('.form');
  const formClose = document.querySelector('.js-form-close');

  formClose?.addEventListener('click',function(){
    form.classList.remove("form_open");
  });


  /* function modal_close() {
    document.querySelector(".modal_open").classList.remove("modal_open");
    document.querySelector(".site-container").classList.remove("dis-scroll");
    window.scrollTo({
      top: distance,
      left: 0,
    });
  } */


  



}