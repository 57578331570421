export const menu = () => {

  const headerNav = document.querySelector('.header__nav');
  const asideMenu = document.querySelector('.menu').outerHTML;
  const headerNavPlace = document.querySelector('.header__nav-place');
  const headerBurger = document.querySelector('.header__burger');


  window.addEventListener("resize",function(){
    menuTransfer();
  });

  menuTransfer();

  function menuTransfer() {
    if ( window.innerWidth < 991 ) {
      if ( headerNavPlace.innerHTML == '' ) {
        headerNavPlace.innerHTML += asideMenu;
      }
    } else {
      headerNavPlace.innerHTML = '';
    }
  }

  headerBurger?.addEventListener('click',function(){
    headerNav.classList.toggle("header__nav_open");
    headerBurger.classList.toggle("header__burger_open");
  });

}