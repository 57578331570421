export const modal = () => {

  const modalOpen = document.querySelectorAll('.js-modal-open');
  const modalClose = document.querySelectorAll('.js-modal-close');
  const modals = document.querySelectorAll('.modal');
  const formSend = document.querySelectorAll('.js-form');
  var distance = 0;

  function disable_scroll() {
    document.querySelector(".site-container").classList.add("dis-scroll");
    document.querySelector(".site-container").scrollTo({
      top: distance,
      left: 0,
    });
  }

  if ( modalOpen ) {
    for (let btn of modalOpen) {
      btn.addEventListener('click',function(e){
        e.preventDefault();
        
        document.querySelector('.modal[data-modal='+e.target.dataset.modal+']').classList.add("modal_open");
        distance = window.pageYOffset;
        disable_scroll();

      });
    }
  }


  function modal_close() {
    document.querySelector(".modal_open").classList.remove("modal_open");
    document.querySelector(".site-container").classList.remove("dis-scroll");
    window.scrollTo({
      top: distance,
      left: 0,
    });
  }


  if ( modalClose ){
    for (let btn of modalClose) {
      btn.addEventListener('click',function(){
        modal_close();
      });
    }
  }

  if ( modal ) {
    for (let modal of modals) {
      modal.addEventListener("mousedown",function(event){
        if (this == event.target){
          modal_close();
        }
      });
    }
  
    document.addEventListener('keydown', function(e) {
      let keyCode = e.keyCode;
      if (keyCode === 27) {
        modal_close();
      }
    });
  }


  if (formSend) {
    for ( let form of formSend) {
      form.addEventListener('submit', e => {
        e.preventDefault();
        let formData = new FormData(form);
        let formSuccess = form.dataset.success;
        let formAction = form.getAttribute("action");
        fetch(formAction, {
            method: "POST",
            body: formData
        }).then(response => response.json()).then(response => {
            if (response.success) {
                form.reset();

                if ( document.querySelector(".modal_open") ) {
                  modal_close();
                }

                if ( document.querySelector(".form_open") ) {
                  document.querySelector(".form_open").classList.remove("form_open");
                }
                
                if( formSuccess ) {
                  document.querySelector('.modal[data-modal='+formSuccess+']').classList.add("modal_open");
                }

            }
        });
      })
    }
  }
  

}