console.log('components');


import { form } from '../js/components/form';
form();

import { modal } from '../js/components/modal';
modal();

import { mask } from '../js/components/mask';
mask();

import { menu } from '../js/components/menu';
menu();