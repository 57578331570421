import Inputmask from 'inputmask';

export const mask = () => {

  document.addEventListener('DOMContentLoaded', () => {
      if(document.querySelectorAll('input[type="tel"]').length > 0) {
        let selector = document.querySelectorAll('input[type="tel"]');
        let im = new Inputmask('+7 (999) 999-99-99', {
          showMaskOnHover: false,
        });
        im.mask(selector);
      }
  })  

}